<template>
  <div class="top-nav">
    <nav class="navbar navbar-expand-md navbar-dark menuarea">
      <div :class="['container']">
        <a :class="['navbar-brand', 'order-1', 'mobile_logo', 'col-6', 'm-0', 'p-0', marketerId === ''? 'filtered_logo': '']" :href="logoURL" v-if="device === 'mobile'">
          <img class="img-fluid" :src="srcLogo" alt="" />
        </a>
        <a class="navbar-brand order-1" :href="logoURL" v-if="device === 'desktop'" style="max-width: 30%;">
          <img class="img-fluid" :src="srcLogo" alt="" />
        </a>
        <div :class="['content-right', 'd-flex', 'order-2', 'order-md-1', (device=== 'mobile' ? 'col-5': '')]">
          <ul class="list-inline mr-auto">
            <li class="list-inline-item" v-if="device === 'mobile' && marketerId === ''">
              <router-link class="navbar-brand order-1" :to="camingoLink">
                <button class="btn btnDomestic btn-theme-onWhite">
                  {{ lang==='he' ? $t("top-nav.domestic-tourism") : $t("top-nav.domestic-tourism-short") }}
                </button>
              </router-link>
            </li>
            <!-- <li :class="['list-inline-item', (device === 'mobile' ? 'mt-10': '')]" v-if="device === 'mobile' && (!isOdysseySite || isMEMSHAKAgent)">
              <a class="navbar-brand order-1" href="https://flying.co.il">
                <button class="btn btnDomestic btn-theme-onWhite">
                  {{ lang==='he' ? $t("top-nav.vacation-country") : $t("top-nav.vacation-country-short") }}
                </button>
              </a>
            </li> -->
            <!--<li class="list-inline-item">
              <button class="btn btnLogin" > {{$t('top-nav.login')}} <i class="far fa-user"></i> </button>
            </li>-->
            <!-- <li class="list-inline-item">
              <button class="btn btn-outline-secondary btn-theme-onWhite">
                {{ $t("top-nav.enrollment") }}
              </button>
            </li> -->
            <!-- <li class="list-inline-item btn-topVacation" v-if="marketerId === '' && (!isOdysseySite || isMEMSHAKAgent)">
              <a class="navbar-brand order-1" href="https://flying.co.il">
                <button class="btn btn-outline-secondary btn-theme-onTheme">
                  {{ $t("top-nav.vacation-country") }}
                </button>
              </a>
            </li> -->
            <li class="list-inline-item" v-if="device === 'desktop'  && marketerId === ''">
              <router-link class="navbar-brand order-1" :to="camingoLink">
                <button class="btn btnDomestic btn-theme-onWhite">
                  {{ $t("top-nav.domestic-tourism") }}
                </button>
              </router-link>
            </li>
            <li class="list-inline-item" v-if="isOdysseySite &&  !isMEMSHAKAgent && currentPage !== 'booking-info'">
              <router-link class="navbar-brand order-1" to="/personal-area">
                <button class="btn btn-theme-onWhite">
                  {{ $t("booking-info.personal-area") }}
                </button>
              </router-link>
            </li>
            <li :class="['list-inline-item']" v-if="device === 'desktop' && marketerId === ''">
              <div id="st-trigger-effects" class="">
                <button class="btn btn-theme-onWhite" data-effect="st-effect-9" v-b-toggle.nav-menu>
                  <i class="fa fa-bars"></i> {{ $t('menu.menu') }}
                </button>
              </div>
            </li>
          </ul>
        </div>
        <ul class="col-1 displayLeft mb-auto content-right" v-if="device === 'mobile'">
          <li :class="['list-inline-item','displayLeft m-0']">
              <div id="st-trigger-effects" class="">
                <button class="btn btn-outline-secondary text-light" data-effect="st-effect-9" v-b-toggle.nav-menu>
                  <i class="fa fa-bars"></i>
                </button>
              </div>
            </li>
        </ul>
      </div>
    </nav>
    <!-- <nav class="navbar navbar-expand-md navbar-dark mb-3 margin-top-10">
      <div class="container justify-content-start">
        <MemberLogin/>
        <MemberRegister/>
      </div>
    </nav> -->
  </div>
</template>

<script>
import { VBToggle } from 'bootstrap-vue';
import { mapGetters } from 'vuex';

export default {
  name: 'TopNav',
  components: {
    // NavMenu: () => import('@/components/menu/NavMenu'),
    // ContentWrapper: () => import('@/components/content/ContentWrapper')
    // MemberLogin: () => import('@/components/atoms/MemberLogin'),
    // MemberRegister: () => import('@/components/atoms/MemberRegister'),
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      whiteLabel: 'GET_WHITE_LABEL_DATA',
      lang: 'GET_LANGUAGE',
      currentPage: 'GET_CURRENT_PAGE',

      marketerId: 'GET_MARKETER_ID',
      marketerAgency: 'GET_MARKETER_AGENCY',
      marketerAgencyContent: 'GET_MARKETER_AGENCY_CONTENT',
      subMarketerId: 'GET_SUB_AGENCY',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isMEMSHAKAgent: 'IS_MEMSHAK_ODYSSEY_AGENT_CODE',
    }),
    camingoLink() {
      return '/'; // process.env.VUE_APP_CAMINGO_LINK;
    },
    srcLogo() {
      const { marketerAgencyContent } = this;
      return (this.marketerAgencyContent === null || this.marketerAgencyContent === undefined) ? this.whiteLabel?.logoUrls?.[0] : marketerAgencyContent?.logoUrls?.[0];
    },
    logoURL() {
      const { marketerId, subMarketerId } = this;
      let url = '/';
      if (marketerId && marketerId !== '') url = `${url}?marketerId=${marketerId}`;
      if (subMarketerId && subMarketerId !== '') url = `${url}&subMarketerId=${subMarketerId}`;
      return url;
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
.margin-top-10{
  margin-top: 4rem;
}
@media (max-width: 990px) {
  .btn-topVacation {
    display: none;
  }
  .btnDomestic {
    width: 99px;
    max-width: 100px;
    padding: 10px;
    line-height: 14px;
    white-space: pre-wrap;
  }
}

@media (max-width: 767px) {
  .margin-top-10{
    margin-top: 0;
  }
}
@media (max-width: 470px) {
  .mt-10 {
    margin-top: -10px;
  }
  .displayLeft {
    float: left;
  }
}

.list-inline-item:not(:last-child) {
  margin-left: 0.5rem;
}
.list-inline {
  padding-right: 0;
}

.top-nav .menuarea .mobile_logo {
  margin-right: 10px;
}
</style>
